import React, { useEffect } from "react";

export default function PrivacyPolicyPrivacyPolicy() {
  return (
    <React.Fragment>
      <div className="container-body mt-5 mt-5">
        <div className="row">
          <div className="col-md-12 tnc">
            <h1 className="tnc-title-1">
              KEBIJAKAN PRIVASI PLATFORM OASIS BAGI PELANGGAN
            </h1>

            <p>
              Platform Oasis merupakan sebuah platform sistem manajemen
              restoran untuk dine in dan online order yang dikembangkan oleh PT
              ARRA ANAK BANGSA. Kebijakan Privasi Platform Oasis Bagi
              Pelanggan ini adalah perjanjian antara Pengembang Platform Oasis
              One dan Pelanggan yang berisikan ketentuan mengenai Data Pribadi
              Pelanggan (untuk selanjutnya disebut dengan “Kebijakan Privasi”).
              Platform Oasis menghormati hal-hal yang berkenaan dengan
              perlindungan privasi Pelanggan. Oleh karena itu, Platform Oasis
              One menyusun Kebijakan Privasi ini untuk menjelaskan kepada
              Pelanggan bagaimana Platform Oasis memperoleh, mengumpulkan,
              mengolah, menganalisis, menggunakan, menyimpan, menampilkan,
              mengumumkan, mengirimkan, menyebarluaskan, menghapus dan
              memusnahkan Data Pribadi Pelanggan, baik pada saat melakukan
              registrasi, mengakses, serta menggunakan layanan Platform Oasis
              One. Pelanggan wajib membaca Kebijakan Privasi ini dengan seksama
              untuk memastikan bahwa Pelanggan telah memahami bagaimana proses
              pengolahan Data Pribadi Pelanggan tersebut. Kecuali diatur lain,
              istilah-istilah dalam huruf kapital yang digunakan di dalam
              Kebijakan Privasi ini memiliki arti yang sama dengan yang terdapat
              dalam Syarat dan Ketentuan Platform Oasis Bagi Pelanggan dan
              merupakan bagian yang tidak terpisahkan dari Kebijakan Privasi
              ini. Dengan menggunakan Platform Oasis, Pelanggan telah
              memahami dan menyetujui Kebijakan Privasi serta Syarat dan
              Ketentuan Oasis Bagi Pelanggan.
            </p>

            <h2 className="tnc-title-2">I. DATA PRIBADI PELANGGAN.</h2>
            <p>
              Data Pribadi Pelanggan adalah data tentang Pelanggan yang
              teridentifikasi atau dapat diidentifikasi secara tersendiri atau
              dikombinasi dengan informasi lainnya baik secara langsung maupun
              tidak langsung melalui sistem elektronik atau non-elektronik. Data
              Pribadi Pelanggan dalam hal ini memuat setiap data, informasi,
              keterangan dalam bentuk apapun yang dapat mengidentifikasikan diri
              Pelangganatau yang Pelanggan cantumkan atau sampaikan melalui
              Platform Oasis yang menyangkut informasi yang mencakup antara
              lain: nama Pelanggan, nomor telepon/handphone Pelanggan, nama
              platform yang telah dilekatkan pada perangkat Pelanggan,
              penelusuran informasi maupun data, informasi, keterangan lainnya
              mengenai kegiatan Pelanggan di dalam menggunakan Platform Oasis
              One dan yang tergolong sebagai Data Pribadi Pelanggan.
            </p>

            <h2 className="tnc-title-2">
              II. KEAKURATAN DATA PRIBADI PELANGGAN.
            </h2>
            <p>
              Platform Oasis membutuhkan Data Pribadi Pelanggan salah
              satunya adalah untuk dapat melakukan pemrosesan yang berhubungan
              dengan layanan yang disediakan oleh Platform Oasis. Oleh
              karena itu, Data Pribadi Pelanggan yang diberikan kepada Platform
              Oasis harus benar dan akurat sesuai fakta yang ada, serta
              dapat dipertanggungjawabkan.
            </p>

            <h2 className="tnc-title-2">
              III. PENGUMPULAN DATA PRIBADI PELANGGAN.
            </h2>
            <p>
              Platform Oasis akan mengumpulkan Data Pribadi Pelanggan pada
              saatPelanggan mengakses Platform Oasis atau pada saat lainnya
              sebagaimana Platform Oasis mintakan kepada Pelanggan apabila
              dibutuhkan dari waktu ke waktu.
            </p>

            <h2 className="tnc-title-2">
              IV. PENGGUNAAN DATA PRIBADI PELANGGAN.
            </h2>
            <p>
              Platform Oasis akan mengolah, menganalisis, dan/atau
              menggunakan Data Pribadi Pelanggan untuk tujuan sebagai berikut
              maupun tujuan lain yang diizinkan oleh peraturan
              perundang-undangan yang berlaku:
            </p>
            <ol className="list-1">
              <li>
                Mengidentifikasi Pelanggan pada saat menggunakan/melakukan akses
                Platform Oasis,
              </li>
              <li>Memproses layanan yang terdapat di Platform Oasis;</li>
              <li>
                Mencegah, mendeteksi, menyelidiki dan mengatasi segala bentuk
                dan jenis atas tindakan yang merupakan kejahatan, dilarang,
                ilegal, tidak sah atau curang, yang mungkin terjadi dalam
                penggunaan Platform Oasis, termasuk namun tidak terbatas
                pada hal-hal yang berhubungan dengan potensi dan/atau temuan
                atas penipuan fraud, penggelapan, pencurian dan pencucian uang,
                serta terorisme, pelanggaran Undang-Undang Informasi Transaksi
                Elektronik dan peraturan perundang-undangan/hukum positif
                terkait lainnya (sebagaimana dimungkinkan); Platform Oasis
                juga dapat menggunakan metode penilaian sendiri untuk melakukan
                penilaian tersebut, hal mana tidak perlu dibuktikan;
              </li>
              <li>
                Pelaksanaan riset mengenai data demografis Pelanggan Platform
                Oasis termasuk namun tidak terbatas pada penggunaan layanan
                Platform Oasis;
              </li>
              <li>
                Mengembangkan, meningkatkan, dan menyediakan layanan, fitur,
                promosi yang terdapat di dalam Platform Oasis;
              </li>
              <li>
                Pengiriman informasi yang Platform Oasis anggap berguna
                untuk Pelanggan termasuk informasi tentang produk, layanan, dan
                promosi dari Platform Oasis;
              </li>
              <li>
                Tujuan administratif internal, seperti; audit, analisis data,
                rekaman-rekaman dalam database;
              </li>
              <li>
                Berkomunikasi dengan Pelanggan sehubungan dengan segala hal
                mengenai Platform Oasis, seperti layanan, fitur dan promosi;
              </li>
              <li>
                Menjaga keselamatan, keamanan, kepatuhan dan keberlangsungan
                Platform Oasis;
              </li>
              <li>
                Pada saat Pelanggan menggunakan gambar dan/atau kata Pelanggan
                yang terdapat di Platform Oasis, dalam hal ini ditujukan
                untuk hal-hal yang terkait dengan layanan Platform Oasis;
              </li>
              <li>
                Berkomunikasi dengan Pelanggan sehubungan dengan layanan
                Platform Oasis melalui saluran yang berbeda (misalnya,
                melalui telepon dan surat elektronik/email);
              </li>
              <li>
                Menampilkan iklan berbasis minat yang mungkin relevan bagi
                Pelanggan;
              </li>
              <li>Memenuhi ketentuan perundang-undangan yang berlaku.</li>
            </ol>

            <h2 className="tnc-title-2">
              V. PENGUNGKAPAN DATA PRIBADI PELANGGAN KEPADA PIHAK KETIGA.
            </h2>
            <p>
              Untuk tujuan pengembangan, peningkatan, perlindungan,
              pemeliharaan, layanan, fitur, promosi, kerjasama, dalam hal ini
              Platform Oasis terkadang diperlukan untuk menampilkan,
              mengumumkan, mengirimkan, dan/atau menyebarluaskan Data Pribadi
              Pelanggan kepada pihak ketiga. Untuk menghindari keragu-raguan,
              pihak ketiga tersebut dalam hal ini termasuk namun tidak terbatas
              pada:
            </p>
            <ol className="list-1">
              <li>
                Mitra usaha/restoran terkait layanan Platform Oasis yang
                digunakan oleh Pelanggan.
              </li>
              <li>
                Perusahaan afiliasi Platform Oasis untuk atau sehubungan
                dengan tujuan yang terkait dengan penyediaan Platform Oasis,
                pengelolaan bisnis dan kegiatan lainnya;
              </li>
              <li>
                Agen, vendor, pemasok, kontraktor atau pihak yang mengadakan
                kerja sama komersial dengan Platform Oasis dengan tujuan
                untuk mendukung Platform Oasis;
              </li>
              <li>
                Pihak ketiga apabila terdapat transaksi atau aksi korporasi
                Pengembang Platform Oasis, seperti: penggabungan, pemisahan,
                pengambilalihan, peleburan atau pembubaran; Platform Oasis
                akan memberitahukan kepada Pelanggan baik sebelum maupun sesudah
                terjadinya keadaan tersebut;
              </li>
              <li>
                Otoritas/institusi yang berwenang sebagaimana disyaratkan atau
                diperintahkan oleh peraturan perundang-undangan yang berlaku.
              </li>
            </ol>

            <h2 className="tnc-title-2">VI. PENYIMPANAN DAN KEAMANAN DATA.</h2>
            <ol className="list-1">
              <li>
                Platform Oasis memastikan bahwa Data Pribadi Pelanggan yang
                dikumpulkan dan/atau terkumpul oleh Platform Oasis akan
                disimpan dengan aman sesuai dengan peraturan perundang-undangan
                yang berlaku di Indonesia.
              </li>
              <li>
                Platform Oasis akan menyimpan Data Pribadi Pelanggan untuk
                memenuhi tujuan yang dijelaskan dalam Kebijakan Privasi ini
                sesuai dengan ketentuan perundang-undangan yang berlaku.
                Platform Oasis akan memberitahukan kepada Pelanggan dalam
                hal terjadi kegagalan perlindungan Data Pribadi Pelanggan sesuai
                dengan ketentuan perundang-undangan yang berlaku.
              </li>
            </ol>

            <h2 className="tnc-title-2">VII. AKSES TIDAK SAH.</h2>
            <ol className="list-1">
              <li>
                Pelanggan wajib untuk menjaga kerahasiaan Data Pribadi
                Pelanggan, data yang Pelanggan gunakan untuk melakukan
                permintaan pemesanan atas produk yang terdapat pada Platform
                Oasis.
              </li>
              <li>
                Pelanggan dalam hal ini bertanggung jawab atas penyalahgunaan
                dan/atau kelalaian sehubungan dengan angka 1 diatas.
              </li>
              <li>
                Pelanggan wajib memberitahukan kepada Platform Oasis
                sehubungan dengan angka 1 diatas.
              </li>
            </ol>

            <h2 className="tnc-title-2">
              VIII. PERMINTAAN AKSES, SALINAN, PERUBAHAN DATA PRIBADI PELANGGAN.
            </h2>
            <ol className="list-1">
              <li>
                Pelanggan dapat meminta akses, salinan Data Pribadi Pelanggan
                dan/atau meminta perubahan Data Pribadi Pelanggan berdasarkan
                Kebijakan Privasi ini, Syarat dan Ketentuan Platform Oasis
                Bagi Pelanggan dan ketentuan perundang-undangan yang berflaku.
              </li>
              <li>
                Platform Oasis berhak menolak permintaan sebagaimana angka 1
                diatas berdasarkan Kebijakan Privasi ini, Syarat dan Ketentuan
                Platform Oasis Bagi Pelanggan dan ketentuan peraturan
                perundang-undangan yang berlaku.
              </li>
            </ol>

            <h2 className="tnc-title-2">
              IX. PENCABUTAN PERSETUJUAN, PENGAKHIRAN PENGGUNAAN DATA PRIBADI
              PELANGGAN.
            </h2>
            <ol className="list-1">
              <li>
                Pelanggan dapat meminta Pengembang Platform Oasis untuk
                menarik kembali persetujuan penggunaan Data Pribadi Pelanggan
                yang telah diberikan. Dalam hal ini Pelanggan memahami dan
                menyetujui bahwa penarikan persetujuan tersebut tidak berdampak
                pada setiap hal yang terjadi sebelum penarikan persetujuan
                penggunaan Data Pribadi Pelanggan.
              </li>
              <li>
                Pelanggan dapat meminta Platform Oasis untuk mengakhiri
                pemrosesan, menghapus dan/atau memusnahkan Data Pribadi
                Pelanggan.
              </li>
              <li>
                Pemberitahuan sehubungan dengan angka 1 dan 2 diatas dilakukan
                melalui surat elektronik (email) Admin Oasis di
                admin@oasis-one.com.
              </li>
              <li>
                Dalam hal terdapat konsekuensi sehubungan dengan angka 1 dan 2
                diatas yang mengakibatkan penghentian layanan Platform Oasis
                kepada Pelanggan, maka Platform Oasis akan memberitahukan
                hal tersebut kepada Pelanggan.
              </li>
            </ol>

            <h2 className="tnc-title-2">X. HUKUM YANG BERLAKU</h2>
            <p>
              Kebijakan Privasi ini diatur dan ditafsirkan sesuai dengan hukum
              di Negara Republik Indonesia.
            </p>

            <h2 className="tnc-title-2">
              XI. PERUBAHAN TERHADAP KEBIJAKAN PRIVASI.
            </h2>
            <p>
              Platform Oasis dapat untuk setiap saat mengubah, memperbarui,
              dan/atau menambahkan sebagian maupun seluruh ketentuan dalam
              Kebijakan Privasi ini. Apabila Kebijakan Privasi ini
              diubah/diperbaharui/ditambahkan maka Platform Oasis akan
              memberitahukan kepada Pelanggan sehubungan dengan hal tersebut.
            </p>

            <h2 className="tnc-title-2">
              XII. PERNYATAAN DAN PERSETUJUAN PELANGGAN.
            </h2>
            <p>
              Pelanggan dengan ini menyatakan dan menyetujui hal-hal sebagai
              berikut:
            </p>
            <ol className="list-1">
              <li>
                Bahwa pemberian Data Pribadi Pelanggan kepada Platform Oasis
                merupakan data yang benar dan sah, oleh karenanya Pelanggan
                memberikan persetujuan kepada Platform Oasis untuk
                memperoleh, mengumpulkan, mengolah, menganalisis, menyimpan,
                menampilkan, mengumumkan, mengirimkan, menyebarluaskan,
                menghapus dan memusnahkan sesuai dengan Kebijakan Privasi ini,
                Syarat dan Ketentuan Platform Oasis Bagi Pelanggan dan
                peraturan perundang-undangan yang berlaku.
              </li>
              <li>
                Bahwa Pelanggan telah membaca, memahami dan menyetujui seluruh
                ketentuan yang terdapat dalam Kebijakan Privasi ini.
              </li>
              <li>
                Seluruh Syarat dan Ketentuan Platform Oasis Bagi Pelanggan
                mengikat dan menjadi satu kesatuan yang tidak terpisahkan dari
                Kebijakan Privasi ini.
              </li>
              <li>
                Bahwa Pelanggan membebaskan Platform Oasis dari setiap
                bentuk tuntutan, keberatan, aduan, gugatan, ganti rugi, klaim
                dan segala akibat hukum sehubungan dengan kegagalan penggunaan
                layanan Platform Oasis yang disebabkan oleh
                ketidakakuratan/ketidakbenaran Data Pribadi Pelanggan berikan
                kepada Platform Oasis.
              </li>
              <li>
                Bahwa Pelanggan telah memberikan persetujuan, izin, dan wewenang
                kepada Pelanggan sehubungan dengan pengungkapan Data Pribadi
                Pelanggan kepada pihak ketiga (termasuk pihak terkait lainnya
                yang bekerja untuk dan di bawah pengawasan Platform Oasis)
                sebagaimana dimaksud dalam Kebijakan Privasi ini.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
