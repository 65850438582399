import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setColor } from "../../../redux/actions/utilsAction";
import HeaderBar from "../../navbar/HeaderBar";
import CallForBill from "./CallForBill";

import cashierPayment from "../../../assets/images/cashierPayment.png";
import onlinePayment from "../../../assets/images/onlinePayment.png";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Payment() {
  const dispatch = useDispatch();
  const currColor = localStorage.getItem("color");
  const color = useSelector((state) => state.color);
  const location = useLocation();
  const { tenant, order } = location.state;

  const [detailToken, setDetailToken] = useState(null);

  async function fetchPaymentToken() {
    const url = `${process.env.REACT_APP_API_URL}api/payment/durianpay/generate-access-token/${order.order_id}`;
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        setDetailToken(response.data);
      }
    } catch (error) {
      console.log("ERROR: ", error.message);
    }
  }

  const [checkOnlinePayment, setCheckOnlinePayment] = useState(false)

  useEffect(() => {
    dispatch(setColor(currColor));
    fetchPaymentToken();
    let mounted = true;

    if (mounted) {
      if (tenant.tenant_id != undefined) {
        const url = process.env.REACT_APP_API_URL + "api/tenant/user/" + tenant.tenant_id;
        fetch(url, {
          method: "GET",
          headers: { "content-type": "application/JSON" },
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.status === "SUCCESS") {
              setCheckOnlinePayment(result.data.pgStatus);
            }
          });
      }
    }
    return () => {
      mounted = false;
    };
  }, []);


  const notify = (message) =>
    toast(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: "toast-message",
    });

  const handlePayment = () => {
    const dpay = window.Durianpay.init({
      locale: "id",
      environment: "production", // Value should be 'production' for both sandbox and live mode
      access_key: detailToken.access_token,
      site_name: process.env.REACT_APP_API_URL,
      dark_mode: false,
      order_info: {
        id : detailToken.id
      },
      container_elem: "pay-btn-container",
  
      onSuccess: function() {
        notify("payment success");
        setTimeout(() => {
          window.location = "/Order?id=" + order.order_id;
        }, 2000);
      },
  
      onFailure: function() {
        notify('Transaction was not completed, transaction failed!');
      },
    });
    dpay.checkout();
  }

  return (
    <React.Fragment>
      <HeaderBar />

      <div
        className="container-body"
        style={{ position: "absolute", width: "414px", maxWidth: "100vw" }}
      >
        <section>
          <h4 className="section-title">Payment Method</h4>
          <div className="row">
            {
              process.env.REACT_APP_TENANT_ID === tenant?.tenant_id
              ? <div className="col-6">
                  <div
                    className={`card card-payment-button active`}
                  >
                    <div className="card-body">
                      <img width={41} height={41} src={cashierPayment} alt="" />
                      <div className="my-3">Pay at Cashier</div>
                    </div>
                  </div>
                </div>
              : null
            }

            <div className="col-6">
              <div
                id="pay-btn-container"
                className={`card card-payment-button ${checkOnlinePayment ? 'active' : ''}`}
                onClick={() => checkOnlinePayment ? handlePayment() : false}
              >
                <div className="card-body">
                  <img width={41} height={41} src={onlinePayment} alt="" />
                  <div className="my-3">Online Payment</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <CallForBill currOrder={order} />
      </div>
      <ToastContainer limit={1} />
    </React.Fragment>
  );
}
