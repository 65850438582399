const CURRENCY_FORMATER = new Intl.NumberFormat(undefined, {
  currency: "IDR",
  style: "currency",
  minimumFractionDigits: 0,
  maximumFractionDigits: 10,
});
export default function formatCurrency(number) {
  if (typeof number === "string") {
    number = number.replace(/[,.]/g, "");
  }

  return CURRENCY_FORMATER.format(number);
}
