import formatCurrency from "../../../utils/formatCurrency";
import { Modal } from "react-bootstrap/";
import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import { SocketContext } from "../../../context/socketContext";
import { convertInt } from "../../../utils/helper";

export default function DetailCurrentOrder({ tenant, order, ...props }) {
  const history = useHistory();

  const [table, setTable] = useState([]);
  useEffect(() => {
    async function fetchOrder(order_table) {
      const url = `${process.env.REACT_APP_API_URL}api/table/retrieve`;
      const response = await axios
        .post(url, { table_id: order_table })
        .catch((err) => {
          console.log("Error: ", err);
        });
      setTable(response.data.data[0].table);
    }
    fetchOrder(order.order_table);
  }, []);
  console.log(order);

  let status = "Pending";
  if (order.order_status === 1) status = "Pending";
  else if (order.order_status === 2) status = "Payment";
  else if (order.order_status === 3) status = "Order Paid";
  else if (order.order_status === 4) status = "Served";
  else if (order.order_status === 5) status = "Complete";
  else status = "Rejected";

  let statusClass = "";
  if (status === "Pending" || status === "Payment")
    statusClass = "bagde-status-pending";
  else if (status === "Served" || status === "Order Paid")
    statusClass = "bagde-status-pending";
  else if (status === "Complete") statusClass = "bagde-status-complete";
  else statusClass = "bagde-status-rejected";

  function callTheWaiter() {
    history.push(`/${tenant.tenant_id}/CallWaiter`);
  }

  return (
    <Modal {...props} dialogClassName={"modal-detail"} size={"sm"} centered>
      <React.Fragment>
        <div onClick={() => props.onHide()} style={{ marginLeft: 'auto', cursor: 'pointer', marginBottom: '12px' }}>X</div>
        <div className="d-flex justify-content-between">
          <div className={`badge bagde-status mt-2 ${statusClass}`}>
            {status}
          </div>
          {order.order_status < 5 && (
            <button
              className="btn btn-call-waiter btn-sm"
              onClick={() => {
                callTheWaiter();
              }}
            >
              Call the Waiter
            </button>
          )}
        </div>

        <div className="order-divider"></div>
      </React.Fragment>

      <table className="table table-bills table-borderless table-sm ">
        <thead className="text-sm">
          <tr>
            <th colSpan={2} style={{ color: "#FF7B01" }}>
              {order.order_id}
            </th>
          </tr>
          <tr>
            <th>Store: {tenant.name}</th>
            <th className="text-right" style={{ color: "#FF7B01" }}>
              {/* Table&nbsp; */}
              {table.isCustomName
                ? table.name
                : "Table " + table.order_table_index}
            </th>
          </tr>
          <tr>
            <th colSpan={2}>
              Time:&nbsp;
              {moment(order.order_time).format("MMMM, Do YYYY; h:mm:ss a")}
            </th>
          </tr>
        </thead>
        <tbody>
          {order.order_menu.map((menu) => {
            return (
              <tr key={menu.id}>
                <td>{`${menu.name} - ${menu.orderQuantity}x`} </td>
                <td className="order-bills">
                  { menu.discount ? <>
                    <span className="menu-card-price" 
                      style={{ textDecoration: 'line-through', color: 'red', fontSize: '12px' }}>
                      <b>{formatCurrency(menu.price)}</b>
                    </span>
                  </> : null }
                  <div>{formatCurrency(menu.total)}</div>
                </td>
              </tr>
            );
          })}

          <tr className="border-top">
            <td>Subtotal :</td>
            <td className="order-bills">{formatCurrency(order.order_total + ((order.tenant_discount?.amount ?? 0)))}</td>
          </tr>
          { order.tenant_discount ? <tr className="border-top">
            <td>Discount {`(${order.tenant_discount.type === 'percentage' ? order.tenant_discount.value + '%' : ''})`} :</td>
            <td className="order-bills">-{formatCurrency(order.tenant_discount.amount)}</td>
          </tr>: null }

          <tr>
            <td>Service Charge ({tenant.serviceCharge}%):</td>
            <td className="order-bills">
              {formatCurrency(order.order_servicecharge)}
            </td>
          </tr>

          <tr className="border-bottom">
            <td>Tax ({tenant.taxCharge}%):</td>
            <td className="order-bills">
              {formatCurrency(order.order_taxcharge)}
            </td>
          </tr>

          <tr>
            <td>Bill Amount :</td>
            <td className="order-bills">
              {formatCurrency(
                order.order_total +
                  order.order_servicecharge +
                  order.order_taxcharge
              )}
            </td>
          </tr>
        </tbody>
      </table>

      {order.order_status < 3 && (
        <div className="row my-2">
          <div className="col-12 text-center">
            <button
              className={`btn btn-payment`} //|| order.order_status === 3
              onClick={() => {
                history.push({
                  pathname: `/Payment`,
                  state: {
                    tenant,
                    order,
                  },
                });
              }}
            >
              Payment
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
}
